import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/aaf0776b25a939673ad39ea139450fd0/aa440/Morne_Birds_eye_view_of_a_massive_city_with_tall_towers_atop_a__0206bbd6-09b9-4ff0-b90f-8f68889a91c9.png",
            "srcSet": ["/static/aaf0776b25a939673ad39ea139450fd0/5ff7e/Morne_Birds_eye_view_of_a_massive_city_with_tall_towers_atop_a__0206bbd6-09b9-4ff0-b90f-8f68889a91c9.png 375w", "/static/aaf0776b25a939673ad39ea139450fd0/1d69c/Morne_Birds_eye_view_of_a_massive_city_with_tall_towers_atop_a__0206bbd6-09b9-4ff0-b90f-8f68889a91c9.png 750w", "/static/aaf0776b25a939673ad39ea139450fd0/aa440/Morne_Birds_eye_view_of_a_massive_city_with_tall_towers_atop_a__0206bbd6-09b9-4ff0-b90f-8f68889a91c9.png 1500w", "/static/aaf0776b25a939673ad39ea139450fd0/71c1d/Morne_Birds_eye_view_of_a_massive_city_with_tall_towers_atop_a__0206bbd6-09b9-4ff0-b90f-8f68889a91c9.png 1536w"],
            "width": "400px",
            "className": "center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`View of the Pal Tenach from an Airship`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`Pal Tenach is a library in `}<a parentName="p" {...{
        "href": "/Meripol",
        "title": "Meripol"
      }}>{`Meripol`}</a>{` used by `}<a parentName="p" {...{
        "href": "/The%20Argent",
        "title": "The Argent"
      }}>{`The Argent`}</a>{` for keeping holy texts and conducting research.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      